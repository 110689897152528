/* eslint-disable no-unused-vars */
import ApiService from "@/core/services/general.service";
import JwtService from "@/core/services/jwt.service";

// Endpoints
const resource = process.env.VUE_APP_API_BASE_GENERAL_URL + "Underwriting";
const reasonBank = [
  {
    reasonKey: "1781711",
    name: "I hate you",
    description: "Hatred",
    status: "Active",
    creationDate: "2021-04-20T14:11:28.41",
    lastModifiedDate: "2021-04-20T14:11:28.41",
  },
  {
    reasonKey: "1781711",
    name: "I envy you",
    description: "Envy",
    status: "Active",
    creationDate: "2021-04-20T14:11:28.41",
    lastModifiedDate: "2021-04-20T14:11:28.41",
  },
];
const underwritingReasonService = {
  get(model) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(`${resource}/reasons`, model);
    }
  },
  create(data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(`${resource}/reason`, data);
    }
  },
  update(data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.put(`${resource}/reason`, data);
    }
  },
  activate(key) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.put(`${resource}/reason/${key}/Active`);
    }
  },
  deactivate(key) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.put(`${resource}/reason/${key}/Inactive`);
    }
  },
  delete(key) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.delete(`${resource}/reason/${key}`);
    }
  },
};

export default underwritingReasonService;
